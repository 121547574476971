import React from 'react'
import {Link} from 'gatsby'
import {Header, Image} from 'semantic-ui-react'
import * as styles from './styles/pagesection.module.css'

const PageSectionComponent = ({sectionList, pageName}) =>  {
	const createLinkContent = ({internalLink,externalLink, content}) => {

		// Handle Links, if any
		if(internalLink!== undefined) {
			if (internalLink !==null) {
				return <Link to={internalLink}>
							{content}
						</Link>
			}
		}
		if(externalLink!== undefined) {
			if (externalLink !==null) {
				return <a
							href={externalLink}
							target="_blank"
							rel="noopener noreferrer"
						>
							{content}
						</a>
			}
		}

		return content
	}

	const mapSectionList = ({type, title, content, items, internalLink, externalLink, imgurl,mobileurl}, depth=0)=> {

		let titleClassName = depth===0 ? styles.header:`${styles.header} ${styles.noMargin}`;
		let titleContent = (
			<React.Fragment>
				{title && <Header className={titleClassName}>{title}</Header> }
			</React.Fragment>
		);
		let preSectionContent = null;
		let sectionContent = null;

		//just in case type wasn't properly assigned a value
		if(type===undefined || type===null) {
			return ""
		}

		if(type==="html") {
			// remove titleContent?
			preSectionContent = titleContent;
			sectionContent = (
				<div
					className={styles.text}
					dangerouslySetInnerHTML={{
						__html: content
					}}
				/>
			)
		}else if(type==="text") {
			preSectionContent = titleContent;
			sectionContent = (<p className={styles.text}>
								{createLinkContent({internalLink, externalLink, content})}
							</p>)
		}else if(type==="image-full") {
			if (mobileurl !== undefined) {
				sectionContent = createLinkContent({internalLink, externalLink, content:<>
					<Image className={styles.fullBlockDesktopOnly} src={imgurl} alt={title} fluid/>
					<Image className={styles.fullBlockMobileOnly} src={mobileurl} alt={title} fluid/>
				</>})
			} else {
				sectionContent = createLinkContent({internalLink, externalLink, content:
					<Image className={styles.fullBlock} src={imgurl} alt={title} fluid/>
				})
			}
		}else if(type==="specification") {
			sectionContent = (<div className={styles.text}>
								<strong>{title}</strong>:
								{createLinkContent({internalLink, externalLink, content})}
							</div>)
		} else if(type.split("-")[0]==="list") {
			let listContent = (
				<React.Fragment>
					{items.map((section, innerIndex)=>{
						if(depth===4 && section.type.split("-")[0]==="list" ) {
							return "";
						}
						return (
							<li key={`${section.type}-${section.content}-${section.title}-${section.imgurl}-depth${depth+1}`} className={styles.listMargin}>{mapSectionList(section, depth+1)}</li>
						)
					})}
				</React.Fragment>
			)
			if(type.split("-")[1]==="numbered") {
				sectionContent = (
					<ol>
						{listContent}
					</ol>
				)
			}else if(type.split("-")[1]==="bullet") {
				sectionContent = (
					<ul>
						{listContent}
					</ul>
				)
			}
			preSectionContent = (
				<React.Fragment>
					{titleContent}
					<div className={styles.text}>
						{createLinkContent({internalLink, externalLink, content})}
					</div>
				</React.Fragment>
			)
		}
		return (
			<React.Fragment>
				{preSectionContent}
				{sectionContent}
			</React.Fragment>
		)
	} // mapSectionList

	return (
		<div>
			{sectionList.map((section, index)=>{
				if(section.content===pageName) {
					return ""
				}
				return (
					<div key={`${section.type}-${section.content}-${section.title}-${section.imgurl}-depth0`}>
						{mapSectionList(section, 0)}
					</div>
				)
			})}
		</div>
	)
}

export default PageSectionComponent
