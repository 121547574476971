import React, {useState} from 'react'
import {Grid} from 'semantic-ui-react'

import AccordionItem from '../AccordionItem'

import * as styles from '../../pages/styles/pages.module.css'

const FAQList = ({list}) => {
	const [faqindex, setFaqindex] = useState(-1)

	const faqClick = (index) => {
		if (faqindex === index) {
			setFaqindex(-1);
		} else {
			setFaqindex(index);
		}
	}

	return (<>
		{list.map((listitem, listidx)=> {
			return <>
				{listitem.hasOwnProperty("jumpid") && <span id={listitem.jumpid} className={styles.anchor}/>}
				<AccordionItem
					handleClick={faqClick}
					itemIndex={listidx}
					isactive={faqindex===listidx}
					title={listitem.title}>
						{listitem.content !== undefined ?
							<>{listitem.content}</>
						:
							<div className={styles.text}>{listitem.text}</div>
						}
				</AccordionItem>
			</>
		})}
	</>)
}

export default FAQList
