import React from 'react'
import {graphql, Link, navigate} from 'gatsby'
import SEO from '../components/SEO'
//import FranchiseSummary from '../components/FranchiseSummary'
import Layout from '../components/Layout'
import PageSection from '../components/PageSection'
import RetryButton from '../components/RetryButton'

import FAQList from '../components/FAQList'

import SlideShow from '../components/SlideShow'

//import ApiConfig from '../../config/api-config'


import {
	Breadcrumb,
	Icon,
	Card,
	Grid,
	Container,
	Item,
	Header,
	Message,
} from 'semantic-ui-react'
import * as styles from './styles/template.module.css'
import * as franchisepagestyles from './styles/franchisepage.module.css'
import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth
} from '../globalVar'



const Negosyo = require('../../lib/negosyo')



class FranchisePageTemplate extends React.PureComponent {
	state = {
		record:this.props.data.negosyoNow.viewFranchise,
		pageLoading:true,
		error:false,
		relatedList:[],
		category:[],
		business:[],
		hasRegistered: false,
		allowedRegister: true,
		registerMessage: "",
		username: "",
		pageSection:this.props.data.negosyoNow.viewFranchise.sections
	}


	componentDidMount() {

		Negosyo.isLoggedIn()
		.then((isLoggedIn)=>{
			if(isLoggedIn) {
				Negosyo.getCurrentUserDetails()
				.then((user)=>{
					this.handleSignIn(user.username);
				}).catch((err)=>{
					this.fetchData();
				})
			} else {
				this.fetchData();
			}
		}).catch((err)=>{
			this.fetchData();
		})


		/*
		// 2020-10-25 Moved after fetch to capture availability
		// 2020-10-26 Moved back to here to check it this will add more data than when its in finally
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event': 'productDetails',
			'ecommerce': {
				'detail': {
					'products': [
						{
							'name': this.state.product.name,
							'id': this.state.product.sku,
							'price': this.state.product.meta.display_price.with_tax.amount,
							'brand': this.state.product.brand ? this.state.product.brand:"Generic",
							'dimension1': this.state.product.available===true ? "InStock":"OutOfStock"
						}
					]
				}
			}
		});
		*/

	}

	handleSignIn = (username) => {
		// Check if already registered
		if (username.length > 0) {
			this.setState({
				username: username,
			}, function() {
				(async () => {
					const response = await Negosyo.checkFranchiseRegistered(username, this.props.pageContext.url);
					var tmpobj = {
						hasRegistered: false,
						allowedRegister: true,
						registerMessage: "",
					};
					if (response.hasOwnProperty("registered") && response.hasOwnProperty("message")  && response.hasOwnProperty("allowed")) {
						if (response.registered) {
							this.proceedButton('resource');
							return;
						} else if (false === response.allowed) {
							document.getElementById('page-related').scrollIntoView({
								behavior: 'smooth'
							});
						}
						tmpobj.hasRegistered = response.registered;
						tmpobj.allowedRegister = response.allowed;
						tmpobj.registerMessage = response.message;
					}
					this.setState(tmpobj, function () {
						this.fetchData();
					});

				})();
			});
		}
	}


	goBack = () => {
		// From Product Summary
		if(this.props.location.state) {

			if(this.props.location.state.cameFrom) {
				const page = this.props.location.state.cameFrom.split('-')[0]
				//not sure if order page is back button or home page or something else
				if( this.props.location.state.cameFrom && page!=="cart") {
					window.history.back();
				}else {
					navigate('/');
				}
			}else {
				navigate('/');
			}

		}else {
			navigate('/');
		}
	}

	fetchData = (retry=false) => {
		const {
			url
		} = this.props.pageContext

		Negosyo.getFranchiseDetails(url)
		.then((data)=>{
			this.setState({
				record:data,
				pageLoading:false,
				relatedList:data.related,
				category:data.category,
				business:data.business,
				pageSection:data.sections
			})
		}).catch((err)=>{
			this.setState({
				error:true,
				pageLoading:false
			})
		})
	}

	proceedButton = (mode) => {
		navigate('/franchise/'+mode+'/'+this.state.record.url);
	}
	jumptosection = (e, sectionid) => {
		if (e) {
			e.preventDefault();
		}
		document.getElementById('page-'+sectionid).scrollIntoView({
			behavior: 'smooth'
		});
	}

	render() {
		const {
			record,
			error,
			pageLoading,
			relatedList,
			category,
			business,
			pageSection
		} = this.state
		const {
			location
		} = this.props
		const {
			fetchData
		} = this
/*
		//https://developers.google.com/search/docs/data-types/product
		var structuredData = {
			"@context": "https://schema.org/",
			"@type": "Product",
			"name": product.name,
			"productID": product.sku,
			"sku": product.sku,
			"image": [
				product.mainImageHref,
			],
			"description": product.summary,
			//"review": {},
			//"aggregateRating": {},
			"offers": {
				"@type": "Offer",
				"url": location.href,
				"priceCurrency": product.meta.display_price.with_tax.currency.toUpperCase(),
				"price": product.meta.display_price.with_tax.amount,
				"itemCondition": "https://schema.org/NewCondition",
				"availability": product.available===true ? "https://schema.org/InStock":"https://schema.org/OutOfStock",
			}
		}
*/

		var websiteurl = "";
		var websitecount = 0;

		var mappedfaqlist = [];
		var imagelist = [];
		var tmpidx = 0;
		while (tmpidx < record.images.length) {
			imagelist.push({
				name:"Image "+(tmpidx+1),
				url:"",
				mainImageHref: record.images[tmpidx].url
			});
			tmpidx++;
		}

		if (record.faqlist) {
			tmpidx = 0;
			while (tmpidx < record.faqlist.length) {
				mappedfaqlist.push({
					title:record.faqlist[tmpidx].title,
					text:record.faqlist[tmpidx].content
				});
				tmpidx++;
			}
		}

		if (record.sociallinks) {
			tmpidx = 0;
			while (tmpidx < record.sociallinks.length) {
				if (record.sociallinks[tmpidx].imgurl.indexOf("_www_") > 0) {
					websiteurl = websiteurl + ";"+ record.sociallinks[tmpidx].url;
					websitecount++;
				}
				tmpidx++;
			}
			if (websiteurl !== "") {
				websiteurl = websiteurl.substring(1);
			}
		}
		return (

			<Layout location={location} backButton={true}>
				<>
					<SEO
						canonicalpath={"/franchise/"+record.url}
						title={record===undefined ? "Franchise Page":record.name}
						image={record.mainImageHref}
						twitterCard={"summary"}
						description={record.summary}
						//structuredData={structuredData}
					/>
					<Container text className={styles.container}>

						<Breadcrumb className={styles.desktopOnly}>
							<Breadcrumb.Section content="Home" as={Link} to="/"/>
							<Breadcrumb.Divider />
							<Breadcrumb.Section content={"Franchise"} as={Link} to={"/franchise/"} />
							<Breadcrumb.Divider />
							<Breadcrumb.Section content={record.name} />
						</Breadcrumb>
						<Breadcrumb className={styles.mobileOnly}>
							<Breadcrumb.Section as={Link} to={"/franchise/"} >
								<Icon name="arrow left" />
								{"Franchise"}
							</Breadcrumb.Section>
						</Breadcrumb>
						<br/>
					</Container>
					{error===true  && (
						<RetryButton
							retryFunc={()=>{
								this.setState({
									error:false,
									pageLoading:true
								})
								fetchData(true)
							}}
							errorMessage={"Unable to load Franchise Information"}
						/>
					)}
					<div className={franchisepagestyles.sectionholder+" "+franchisepagestyles.homebanner} onClick={(e)=>{this.jumptosection(e, "details")}}>
						<div className={styles.containerbanner}>
							<PageSection sectionList={[{
												type: "image-full",
												imgurl:record.bannerImage.desktopurl,
												mobileurl:record.bannerImage.mobileurl
											}]} pageName={record.name} />
						</div>
					</div>

					{record.description &&
						<div className={franchisepagestyles.descriptionarea}>
							<span id="page-about" className={styles.anchor}/>
							<Container text className={styles.container}>
								<Header className={franchisepagestyles.descriptionareaheader+" "+styles.header}><h1>{record.name}</h1></Header>
								{record.description.split("\n").map((content) => {
										if (content.length < 1) {
											return <></>
										}
										return <p className={styles.text}>{content}</p>
									})}
							</Container>

						</div>
					}
					<div className={styles.containerbanner+" "+franchisepagestyles.sectionholder+" "+franchisepagestyles.borderedregion}>
						<div className={franchisepagestyles.slideshowholder}>
							<SlideShow
									image={imagelist}
									imageratio={1}
									showArrow={false}
								/>

						</div>
						<div className={franchisepagestyles.specificationsarea}>
							<span id="page-details" className={styles.anchor}/>
							<div>
								<label>Industry:</label><span>{record.industry}</span>
							</div>
							<div>
								<label>Franchisor:</label><span>
									<Link to={`/organization/${record.organizationUrl}/`} className={styles.link}>
										{record.organizationName}
									</Link>
								</span>
							</div>
							<div>
								<label>Terms:</label><span>{record.terms}</span>
							</div>
							<div>
								<label>Royalty:</label><span>{record.royalty}</span>
							</div>
							<div>
								<label>Formats:</label><span>{record.deployment}</span>
							</div>
							<div>
								<label>Franchise Fee:</label><span>{record.franchisefee}</span>
							</div>
							<div>
								<label>Investment:</label><span>{record.investment}</span>
							</div>
							{websiteurl !== "" &&
								<div>
									<label>Website:</label><span>
										{websiteurl.split(";").map((siteurl, siteidx)=>{
											return <><a target={"_blank"} href={siteurl} rel={"noopener noreferrer"} title={"Visit "+siteurl}>{siteurl}</a> &nbsp;&nbsp;&nbsp; </>

										})}
									</span>
								</div>
							}
							{record.sociallinks&&<>
								{(record.sociallinks.length - websitecount)>0 && <div className={franchisepagestyles.socialiconarea}>
									<label>Social Media:</label>
									<span>
										{record.sociallinks.map((socialitem, socialitemidx)=> {
											if (socialitem.imgurl.indexOf("_www_") > 0) {
												return <></>
											}
											return <a target={"_blank"} href={socialitem.url} rel={"noopener noreferrer"} title={socialitem.name}>
															<img src={socialitem.imgurl} alt={socialitem.name} />
														</a>
										})}
									</span>
								</div>}
							</>}
						</div>
					</div>
					<span id="page-related" className={styles.anchor}/>
					<div className={franchisepagestyles.proceedarea}>
						<Container>
							{this.state.pageLoading === false?
							<>
								{this.state.hasRegistered ?
									<button
										className={"ui button "+franchisepagestyles.proceedButton} onClick={()=>this.proceedButton('resource')}
									>
										Already Registered: View Resources
									</button>
								:
									<>{this.state.allowedRegister ?
										<>
											{ record.available!==false  &&
												<button className={"ui button "+franchisepagestyles.proceedButton} onClick={()=>this.proceedButton('apply')}> Apply Now</button>
											}
										</>
									:
										<Message warning className={styles.msg}>
											<Message.Header>
												Already Applied
											</Message.Header>
											{"Status:"+this.state.registerMessage}
										</Message>
									}</>
								}
							</>
							:
							<Message warning className={styles.msg}>
								<Message.Header>
									Loading...
								</Message.Header>
							</Message>

							}

						</Container>
					</div>
					{( record.available===false && pageLoading===false ) && (
						<Message warning className={styles.msg}>
							<Message.Header>
								Franchise no longer available.
							</Message.Header>
							Want this item back? Let us know through our <Link to={"/request-product/"} state={{productName:record.name}}>Request a Franchise</Link> form
						</Message>
					)}
				</>
				{mappedfaqlist.length > 0 && <Container className={styles.container}>
					<span id="page-faq" className={styles.anchor}/>
					<Header className={styles.superHeader+" "+styles.minBottomMargin}> Frequently Asked Questions </Header>
					<div>Got Questions? Great, here's what you<div>need to know before we get started.</div></div>
					<FAQList
						list={mappedfaqlist}
						/>
				</Container>}
				<Container className={styles.container}>
					<div className={"ui segment "+styles.container}>
						<button className={"fluid ui button "+styles.seemoreButton} onClick={()=>this.goBack()}> Go Back </button>
					</div>
				</Container>
			</Layout>
		)
	}
}

export default FranchisePageTemplate

export const data = graphql`
	query FranchiseQuery($url:String!) {
		negosyoNow {
			viewFranchise( url: $url ) {
				id
				name
				summary
				description
				url
				mainImageHref
				slug
				organizationName
				organizationUrl
				sku
				available
				terms
				deployment
				franchisefee
				royalty
				investment
				industry
				images {
					thumbnail
					url
				}
				sections {
					content
					externalLink
					imgurl
					internalLink
					items {
						type
						title
						internalLink
						imgurl
						externalLink
						content
					}
					type
					title
				}
				bannerImage {
					desktopurl
					mobileurl
				}
				faqlist {
					title
					content
				}
			}
		}
	}
`



