import React, { Component } from 'react'
import * as styles from '../../pages/styles/pages.module.css'
import * as localstyles from './accordionitem.module.css'
import {
	Header,
	Image,
} from 'semantic-ui-react'

import imageplus from "../../images/icons/plus.png"
import imageminus from "../../images/icons/minus.png"

export default class AccordionItem extends Component {
	handleClick = () => {
		this.props.handleClick(this.props.itemIndex)
		//this.setState({ isactive: !this.state.isactive })
	  }
	render() {
		const {
			isactive,
		} = this.props

		return (
			<React.Fragment>
				<div className={isactive === false ? localstyles.inactive : localstyles.active}>
					<Header
						className={styles.header+" "+localstyles.title}
						onClick={this.handleClick}
					>
						<Image alt={isactive === false? "+" : "-"} src={isactive === false? imageplus : imageminus} />
						<span>{this.props.title}</span>
					</Header>
					{isactive === true &&
						<div className={localstyles.body}>
							{this.props.children}
						</div>
					}
				</div>

			</React.Fragment>
		)
	}
}
