// extracted by mini-css-extract-plugin
export var borderedregion = "franchisepage-module--borderedregion--984cd";
export var descriptionarea = "franchisepage-module--descriptionarea--abe3a";
export var descriptionareaheader = "franchisepage-module--descriptionareaheader--142c3";
export var descriptionsubarea = "franchisepage-module--descriptionsubarea--0c0f1";
export var homebanner = "franchisepage-module--homebanner--bec4f";
export var homebannerimage = "franchisepage-module--homebannerimage--50b2f";
export var homebannermenu = "franchisepage-module--homebannermenu--90e84";
export var proceedButton = "franchisepage-module--proceedButton--bb2d9";
export var proceedarea = "franchisepage-module--proceedarea--ddc32";
export var sectionholder = "franchisepage-module--sectionholder--f551c";
export var slideshowholder = "franchisepage-module--slideshowholder--4829a";
export var socialiconarea = "franchisepage-module--socialiconarea--973f1";
export var specificationsarea = "franchisepage-module--specificationsarea--865bf";